@font-face {
    font-family: "Proxima Nova";
    src: local("proxima-nova-regular"), url(./assets/fonts/proxima-nova-regular.otf) format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: local("proxima-nova-bold"), url(./assets/fonts/proxima-nova-bold.otf) format("opentype");
    font-weight: bold;
}

@font-face {
    font-family: "Proxima Nova";
    src: local("proxima-nova-thin"), url(./assets/fonts/proxima-nova-thin.otf) format("opentype");
    font-weight: lighter;
}

@import "~antd/dist/antd.css";

html,
body,
#root {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: "Proxima Nova";
    font-size: 1rem;
}

.containerx {
    width: 100%;
    margin: auto;
    padding-left: 34px;
    padding-right: 34px;
}

.flex {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1920px) {
    .containerx {
        max-width: 1200px;
    }
}

@media screen and (max-width: 1366px) {
    .containerx {
        max-width: 1000px;
    }
}

@media screen and (max-width: 987px) {
    .page-header {
        .links,
        .social {
            display: none !important;
        }
        .separator {
            display: none;
        }
    }

    .banner {
        height: 610px !important;
        .title {
            font-size: 2rem !important;
        }
    }

    .section {
        .title {
            font-size: 1.5rem !important;
        }

        .items.containerx {
            flex-direction: column;
            .feature-item {
                display: flex;
                margin: 8px;
            }
        }

        .typescript.containerx {
            flex-direction: column;
            .left-img {
                margin-right: 0;
                margin-bottom: 21px;
                width: 100%;
            }
        }

        .api-explorer.containerx {
            flex-direction: column;
            .left-img {
                margin-left: 0;
                margin-top: 21px;
                width: 100%;
            }
        }

        .quotes {
            .quote-list {
                flex-direction: column;
                .quote {
                    margin: 8px;
                }
            }
        }

        .trusted-by {
            display: none;
        }
    }

    .footer-content.containerx {
        .cols {
            .d-column {
                > div {
                    min-height: 89px !important;
                }
                &:not(:last-child) {
                    display: none;
                }
            }
        }

        .input-group {
            margin-top: 8px !important;
        }

        .copyright {
            margin-top: 21px;
        }
    }
}
